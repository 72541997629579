import Vue from 'vue'
import Cookies from "js-cookie";
import Element from "element-ui";
import App from './App.vue'
import router from "./router.js";
import store from "./store";
import api from "@/api";
import './style.css';
import "./styles/element-variables.scss";
import "./assets/iconfont/iconfont.css";
import "@/styles/index.scss"; // global css
import i18n from "./lang"; // internationalization
import "./permission";
import './element-variables.scss'
import VueClipboard from 'vue-clipboard2';
import VueWechatTitle from 'vue-wechat-title'
import Aegis from 'aegis-web-sdk';
import hevueImgPreview from 'hevue-img-preview'
import 'cherry-markdown/dist/cherry-markdown.css';
import '@/assets/svgIcons/index'
import 'element-ui/lib/theme-chalk/icon.css'

Vue.use(api);
Vue.use(VueWechatTitle);
Vue.use(VueClipboard)
Vue.use(Element, {
  size: Cookies.get("size") || "medium", // set element-ui default size
  i18n: (key, value) => i18n.t(key, value),
});

Vue.use(hevueImgPreview)

Vue.config.productionTip = false;
const userinfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')):{}
// 埋点初始化
const aegis = new Aegis({
  id: 'jmdBcYdasAHKTOGazt', // 应用ID，即上报ID
  uin: userinfo?.loginData?.userId ?? 999, // 用户唯一 ID（可选）
  reportApiSpeed: true, // 接口测速
  reportAssetSpeed: true, // 静态资源测速
  hostUrl: 'https://rumt-zh.com', // 上报域名，中国大陆 rumt-zh.com
  spa: true, // spa 应用页面跳转的时候开启 pv 计算
  env: window.location.origin.includes('https://qyx.qq.com') ? Aegis.environment.production:Aegis.environment.development,
});
Vue.prototype.$aegis = aegis;
// console.log('aegis',aegis);




new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
